import ProjectRoll from '../../components/ProjectRoll'
import Layout from '../../components/Layout'
import React from 'react'

const ProjectPage = () => (
  <Layout
    description={'Development Projects completed by Thoaif'}
    title={'Projects'}
  >
    <section className="section">
      <div className="container">
        <div
          className="content"
          style={{
            paddingTop: '48px',
          }}
        >
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <ProjectRoll />
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default ProjectPage
